import React, { useEffect, useState } from 'react';

export default function Test({ location, data }) {


    return (
        <section className="">
            <div className="h-40v bg-primary-blue"></div>
              <div>
    <svg  viewBox="0 0 1440 285" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1440 -2V34.2881V34.2881C1426.13 110.841 1362.67 168.598 1285.15 175.22L0 285V-2H1440Z" fill="#0F4D92"/>
</svg>


        </div>
        </section>
      
    )
}